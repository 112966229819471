import Excel from 'exceljs';
import get from 'lodash/get';
import reduce from 'lodash/reduce';
import padStart from 'lodash/padStart';
// Expected headers

const AgmUploadTransformer = upload => {
  return new Excel.Workbook().xlsx.load(upload).then(workbook => {
    const trackingSheet = workbook.worksheets[0];
    const latSheet = workbook.worksheets[3];
    const cellSheet = workbook.worksheets[4];

    let cellData = {};

    try {
      cellData = reduce(
        Array.from(Array(cellSheet.rowCount)).map((_, idx) => idx + 1),
        (result, idx) => {
          const agm = cellSheet.getRow(idx).getCell(1).value;
          return {
            ...result,
            [(agm || '').toString()]: cellSheet.getRow(idx).getCell(2).value
          };
        },
        {}
      );
    } catch (err) {
      console.log(err);
    }

    // Lets get header data
    const headerColumnMap = {};
    trackingSheet.getRow(11).eachCell(function(cell, colNumber) {
      const headerKey = get(cell.value, 'result', cell.value);
      if (typeof headerKey === 'string') {
        headerColumnMap[
          headerKey
            .toLowerCase()
            .replace(/ /g, '_')
            .replace(/[\W]+/g, '')
        ] = colNumber;
      }
    });
    const data = Array.from(Array(trackingSheet.rowCount - 12))
      .map((_, idx) => idx + 1)
      .filter(idx => !!trackingSheet.getRow(idx + 11).getCell(2).value)
      .map(idx => {
        const row = trackingSheet.getRow(idx + 11);
        return reduce(
          headerColumnMap,
          (result, col, header) => ({
            ...result,
            [header]: get(
              row.getCell(col).value,
              'result',
              row.getCell(col).value
            )
          }),
          {}
        );
      });
    // Now get lat / lng data
    const latLngData = reduce(
      Array.from(Array(latSheet.rowCount))
        .map((_, idx) => idx + 1)
        .filter(idx => !!latSheet.getRow(idx).getCell(2).value),
      (result, idx) => {
        const agm = latSheet.getRow(idx).getCell(1).value;
        return {
          ...result,
          [agm.toString()]: {
            agm,
            lat: `${latSheet.getRow(idx).getCell(2).value}`,
            lng: `${latSheet.getRow(idx).getCell(3).value}`
          }
        };
      },
      {}
    );

    //   Final data
    return data.map((d, idx) => {
      return {
        order_reference: `${new Date().getTime()}-${padStart(idx, 3, '0')}`,
        description_of_location: d.description_of_agm_location,
        run_distance: Math.round(d.run_dist_ft),
        marker_mile_number: d.agm,
        site_description: d.site_description,
        mp:
          d.mp?.toFixed?.(2)?.toString() ||
          (d.run_dist_ft / 5280).toFixed?.(2).toString(),
        diameter: d.d?.toFixed?.(2)?.toString() || '0.00',
        latitude: get(latLngData[d.agm.toString()], 'lat', null),
        longitude: get(latLngData[d.agm.toString()], 'lng', null),
        agm_type: cellData[d.agm.toString()] || 'agm'
      };
    });
  });
};

export default AgmUploadTransformer;
