import { firestoreAction } from 'vuexfire';
import { db, functions } from '@/main';
import firebase from 'firebase';
import reduce from 'lodash/reduce';
export default {
  setPipelinesRef: firestoreAction(
    ({ bindFirestoreRef, state }, { ref, callback }) => {
      if (state.pipelines !== null) {
        callback(false);
        return;
      }
      bindFirestoreRef('pipelines', ref).then(() => {
        callback();
      });
    }
  ),

  setActivePipeline: firestoreAction(
    ({ bindFirestoreRef, state }, { id, callback }) => {
      const ref = db.collection('pipelines').doc(id);
      bindFirestoreRef('activePipeline', ref).then(() => {
        callback();
      });
      bindFirestoreRef(
        'activePipelineData.agms',
        ref
          .collection('agms')
          .orderBy('marker_mile_number')
          .limit(500)
      );
      bindFirestoreRef(
        'activePipelineData.tests',
        ref.collection('tests').orderBy('active', 'desc')
      );
      bindFirestoreRef(
        'activePipelineData.contractors',
        db
          .collection('pipelines')
          .doc(id)
          .collection('contractors')
      );

      bindFirestoreRef(
        'activePipelineData.phones',
        db
          .collection('pipelines')
          .doc(id)
          .collection('notified')
      );
    }
  ),

  unsetActivePipeline: firestoreAction(({ state, commit }, { callback }) => {
    if (!state.activePipeline) {
      callback(false);
      return;
    }
    commit('unsetActivePipeline');
    callback(true);
  }),

  createPipeline({ commit }, payload) {
    commit('setLoading', true);
    const pipelineRef = db.collection('pipelines').doc();
    const { ...docData } = payload;
    return pipelineRef
      .set({
        ...docData,
        hidden: false,
        id: pipelineRef.id
      })
      .then(() => {
        commit('setLoading', false);
        return Promise.resolve();
      });
  },

  async deletePipeline({ commit, dispatch }, payload) {
    commit('setLoading', true);
    try {
      const { docs: contractors } = await db
        .collection(`/pipelines/${payload.id}/contractors`)
        .get();
      if (contractors.length > 0) {
        // Delete each of these individually
        await reduce(
          contractors,
          (carry, { id }) => {
            return carry.then(() =>
              dispatch('unAssignContractor', {
                pipe_contractor_id: id,
                pipe_id: payload.id
              })
            );
          },
          Promise.resolve()
        );
      }

      await db
        .collection('pipelines')
        .doc(payload.id)
        .update({
          hidden: true
        });
    } catch (error) {
      alert(error.message);
    }

    commit('setLoading', false);
  },

  updatePipeline({ commit }, payload) {
    commit('setLoading', true);
    const { id: pipelineID, ...docData } = payload;
    const PipelineRef = db.collection('pipelines').doc(pipelineID);
    return PipelineRef.update({
      name: docData.name,
      description: docData.description,
      client_id: docData.client_id
    }).then(() => {
      commit('setLoading', false);
      return Promise.resolve();
    });
  },

  async uploadExcelSheet(context, { pipe_id, file }) {
    const storageRef = firebase.storage().ref();
    const pipelineSheets = storageRef.child(`runs/${pipe_id}`);
    pipelineSheets
      .put(file)
      .then(snapshot => snapshot.ref.getDownloadURL())
      .then(function(download_url) {
        return db
          .collection('pipelines')
          .doc(pipe_id)
          .update({
            excel_upload: download_url
          });
      });

    // Upload that pipeline
  },

  async assignContractor({ commit }, { pipe_id, contractor_id }) {
    commit('setLoading', true);
    try {
      const func = functions.httpsCallable('assignContractor');
      await func({ pipe_id, contractor_id });
    } catch (err) {
      console.log(err);
    }
    commit('setLoading', false);
  },
  async unAssignContractor({ commit }, { pipe_contractor_id, pipe_id }) {
    commit('setLoading', true);
    try {
      const func = functions.httpsCallable('unassignContractor');
      await func({ pipe_contractor_id, pipe_id });
    } catch (err) {
      console.log(err);
    }
    commit('setLoading', false);
  }
};
