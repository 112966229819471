import { firestoreAction } from 'vuexfire';
import { db, functions } from '@/main';

export default {
  async clonePipelineTestUpToDate({ commit }, { pipe_id, test_id, timestamp }) {
    commit('setLoading', true);
    try {
      const func = functions.httpsCallable('clonePipelineTestUpToDate');
      await func({ pipeId: pipe_id, testId: test_id, timestamp });
    } catch (err) {
      console.log(err);
    }
    commit('setLoading', false);
  },

  setActiveTest: firestoreAction(
    async ({ bindFirestoreRef, unbindFirestoreRef }, { ref, callback }) => {
      bindFirestoreRef('activeTest', ref).then(() => {
        callback();
      });
      bindFirestoreRef(
        'activeTestAgms',
        ref.collection('agms').orderBy('marker_mile_number')
      );
    }
  ),

  updateTestAgm({ commit, getters }, { test_id, payload: { id, ...rest } }) {
    commit('setLoading', true);
    return db
      .collection('pipelines')
      .doc(getters.pipeline.id)
      .collection('tests')
      .doc(test_id)
      .collection('agms')
      .doc(id)
      .update(rest)
      .then(() => {
        commit('setLoading', false);
      })
      .catch((err) => {
        commit('setLoading', false);
        throw err;
      });
  },

  deleteTestAgm({ commit, getters }, { test_id, agm_id }) {
    commit('setLoading', true);
    return db
      .collection('pipelines')
      .doc(getters.pipeline.id)
      .collection('tests')
      .doc(test_id)
      .collection('agms')
      .doc(agm_id)
      .delete()
      .then(() => {
        commit('setLoading', false);
      })
      .catch((err) => {
        commit('setLoading', false);
        throw err;
      });
  },

  createTest({ commit, getters }, payload) {
    commit('setLoading', true);
    const testRef = db
      .collection('pipelines')
      .doc(getters.pipeline.id)
      .collection('tests')
      .doc();
    const batch = db.batch();
    getters.tests
      .filter((test) => test.active)
      .forEach((test) =>
        batch.update(
          db
            .collection('pipelines')
            .doc(getters.pipeline.id)
            .collection('tests')
            .doc(test.id),
          { active: false }
        )
      );
    return batch
      .set(testRef, {
        ...payload,
        id: testRef.id,
        active: true,
        // created: new Date(payload.created)
      })
      .commit()
      .then(() => {
        commit('setLoading', false);
        return Promise.resolve();
      });
  },

  async deleteTest({ commit, getters }, test_id) {
    commit('setLoading', true);
    const test = await db
      .collection('pipelines')
      .doc(getters.pipeline.id)
      .collection('tests')
      .doc(test_id)
      .get();
    await test.ref.delete();
    commit('setLoading', false);
  },
};
