import { db } from '@/main';
import reduce from 'lodash/reduce';
import padStart from 'lodash/padStart';

export default {
  createAGM({ commit, getters }, payload) {
    commit('setLoading', true);
    const agmRef = db
      .collection('pipelines')
      .doc(getters.pipeline.id)
      .collection('agms')
      .doc();
    return agmRef
      .set({
        ...payload,
        order_reference: `${new Date().getTime()}-${padStart(0, 3, '0')}`, // on create, a reference for where the AGM was uploaded. This is how the dataviewer sorts
        id: agmRef.id
      })
      .then(() => {
        commit('setLoading', false);
        return Promise.resolve();
      });
  },

  createAGMS({ commit, getters }, agms) {
    commit('setLoading', true);
    return reduce(
      agms,
      (result, agm) =>
        result.then(() => {
          const agmRef = db
            .collection('pipelines')
            .doc(getters.pipeline.id)
            .collection('agms')
            .doc();
          return agmRef.set({
            ...agm,
            id: agmRef.id
          });
        }),
      Promise.resolve()
    ).then(() => {
      commit('setLoading', false);
      return Promise.resolve();
    });
  },

  updateAGM({ commit, getters }, payload) {
    commit('setLoading', true);
    const { id: agmID, ...docData } = payload;
    const agmRef = db
      .collection('pipelines')
      .doc(getters.pipeline.id)
      .collection('agms')
      .doc(agmID);
    return agmRef
      .update({
        ...docData
      })
      .then(() => {
        commit('setLoading', false);
        return Promise.resolve();
      });
  },

  deleteAGM({ commit, getters }, payload) {
    commit('setLoading', true);
    return db
      .collection('pipelines')
      .doc(getters.pipeline.id)
      .collection('agms')
      .doc(payload.id)
      .delete()
      .then(() => {
        commit('setLoading', false);
        return Promise.resolve();
      });
  }
};
